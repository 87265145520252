var render = function render(){var _vm=this,_c=_vm._self._c;return _c('vue-timepicker',{ref:"timePicker",attrs:{"disabled":_vm.disabled,"placeholder":_vm.placeholder,"error":_vm.error,"minute-interval":_vm.step,"format":_vm.format,"close-on-complete":_vm.closeOnComplete,"required":_vm.required,"auto-scroll":"","hide-disabled-items":"","advanced-keyboard":"","input-class":{
    'time-picker__input': true,
    'time-picker__input--error': _vm.error,
    'time-picker__input--disabled': _vm.disabled,
  }},on:{"input":_vm.onInput},scopedSlots:_vm._u([(!_vm.withoutIcon)?{key:"icon",fn:function(){return [_c('i',{staticClass:"fal fa-clock",class:{
        'time-picker__icon--disabled': _vm.disabled,
      }})]},proxy:true}:null,{key:"clearButton",fn:function(){return [_c('i',{staticClass:"fal fa-times"})]},proxy:true}],null,true),model:{value:(_vm.localValue),callback:function ($$v) {_vm.localValue=$$v},expression:"localValue"}})
}
var staticRenderFns = []

export { render, staticRenderFns }