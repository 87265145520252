<template>
  <div>
    <modal
      :is-open="isOpen"
      @close="closeModalAndClear"
      :footer-light="lightFooter()"
      :footer-center="centerFooter()"
      :footer-clear="confirmationStep()"
      :footer-confirmation="confirmationStep()"
    >
      <template #header v-if="showHeader">
        <h6 class="page__header">join waitlist at {{ facilityName }}</h6>
        <steps
          :current-step="navStepsCurrent"
          :total-steps="navStepsTotal"
          />
        <p class="step-title"> {{ stepTitle }} </p>
      </template>
      <component
        :is="currentStepComponent"
        v-bind="currentProperties"
        :waitlist="currentProperties"
        :dependentsWithSpots="dependentsWithSpots"
        :setLoading="setLoading"
      />
      <template #footer>
        <div>
          <system-button
            v-if="!confirmationStep()"
            :style-type="buttonStyleType()"
            @click="onPrevStep">
            {{ prevBtnText }}
          </system-button>
          <system-button
            v-if="!paymentStep()"
            @click="onNextStep"
            :disabled="disableButton">
            {{ nextStepBtnText }}
          </system-button>
          <system-button
            v-if="paymentStep()"
            @click.once="onNextStep"
            :disabled="disableButton">
            {{ nextStepBtnText }}
          </system-button>
        </div>
      </template>
    </modal>
    <template v-if="loading">
      <loader :loading="loading" />
    </template>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import Loader from '@components/loader/loader.vue';
import Modal from '@components/modal/modal.vue';
import Steps from '@components/progress/steps.vue';
import SystemButton from '@components/buttons/system-button.vue';
import providerProp from '@mixins/providerProp';
import dependentsOlderThanFacilityAgeRange from '@mixins/dependentsOlderThanFacilityAgeRange';
import AgeWarning from '@components/legup/age-warning.vue';
import FeeWarning from '@parent/components/join-waitlist/fee-warning.vue';
import FamilyInfo from '@parent/components/join-waitlist/family-info.vue';
import Questions from '@parent/components/join-waitlist/questions.vue';
import Subsidies from '@parent/components/join-waitlist/subsidies.vue';
import SelectCareSchedules from '@parent/components/join-waitlist/select-care-schedules.vue';
import PaymentForm from '@parent/components/join-waitlist/payment-form.vue';
import Confirmation from '@parent/components/join-waitlist/confirmation.vue';
import TourRequired from '@parent/components/join-waitlist/tour-required.vue';
import { regexPatterns } from '@utils';

export default {
  name: 'legup-waitlist',
  components: {
    Loader,
    Modal,
    Steps,
    SystemButton,
    AgeWarning,
    FeeWarning,
    FamilyInfo,
    Questions,
    Subsidies,
    SelectCareSchedules,
    PaymentForm,
    Confirmation,
    TourRequired,
  },
  mixins: [providerProp, dependentsOlderThanFacilityAgeRange],
  props: {
    isOpen: {
      type: Boolean,
      required: false,
    },
    closeModal: {
      type: Function,
      required: true,
    },
    dependentsWithSpots: {
      type: Array,
      default() {
        return [];
      },
    },
    hasWaitlist: {
      type: Boolean,
      required: false,
    },
    tourBooked: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      currentStep: 0,
      ageGroupPrograms: [],
      loading: false,
      automaticDiscount: false,
      localWaitlistData: {
        dependentId: undefined,
        startCare: '',
        phoneNumber: '',
        parentSubsidiesEnabled: [],
        parentComment: '',
        selectedSchedule: [],
        dependentNames: [],
        answers: [],
        subsidies: [],
        showSubsidies: undefined,
      },
      bus: new Vue(),
      user: this.$store.state.user,
      amount: '',
      stepsData: [{
        component: TourRequired,
        stepName: 'tourRequired',
        title: 'Wait a second!',
        prevBtnText: 'Go back',
        nextBtnText: 'Schedule a tour',
      },
      {
        component: FeeWarning,
        stepName: 'feeWarning',
        title: 'Join the waitlist',
        prevBtnText: 'Cancel',
        nextBtnText: 'Continue',
      },
      {
        component: FamilyInfo,
        stepName: 'familyInfo',
        title: 'Add family info',
        prevBtnText: 'Cancel',
        nextBtnText: 'Next',
      },
      {
        component: SelectCareSchedules,
        stepName: 'selectSchedule',
        title: 'Select care schedule',
        prevBtnText: 'Back',
        nextBtnText: 'Next',
      },
      {
        component: Questions,
        stepName: 'questions',
        title: 'Provider questions',
        prevBtnText: 'Back',
        nextBtnText: 'Next',
      },
      {
        component: Subsidies,
        stepName: 'subsidies',
        title: 'Subsidy info',
        prevBtnText: 'Back',
        nextBtnText: 'Next',
      },
      {
        component: PaymentForm,
        stepName: 'paymentForm',
        title: 'Pay waitlist fee',
        prevBtnText: 'Back',
        nextBtnText: 'Submit',
      },
      {
        component: Confirmation,
        stepName: 'confirmation',
        prevBtnText: '',
        nextBtnText: 'Okay',
      }],
    };
  },
  computed: {
    ...mapGetters({
      currentPayment: 'payments/currentPayment',
    }),
    facility() {
      return this.provider.currFacility;
    },
    facilityName() {
      return this.facility?.name ?? 'this facility';
    },
    legupCenterId() {
      return this.facility?.legupCenterId;
    },
    currentStepComponent() {
      this.currentStepCalc();
      return this.stepsData[this.currentStep].component;
    },
    currentProperties() {
      return {
        automaticDiscount: this.automaticDiscount,
        waitlistData: this.localWaitlistData,
        ageGroupPrograms: this.ageGroupPrograms,
        centerId: this.legupCenterId,
        facilityName: this.facilityName,
        facilityId: this.facility?.id,
        parentId: this.user.current.id,
        onChange: this.onChange,
        bus: this.bus,
        submitStep: this.submitStep,
        providerId: this.facility?.providerId,
        olderChildren: this.olderChildren,
        centerQuestions: this.$store.state.legup.centerQuestions,
      };
    },
    dependentValues() {
      return this.$store.getters['dependents/asArray'];
    },
    totalSteps() {
      return Object.keys(this.stepsData).length;
    },
    navStepsTotal() {
      if (this.questionsAndSubsidies) {
        return this.totalSteps - 3;
      }
      if (this.subsidiesNoQuestions || this.questionsNoSubsidies) {
        return this.totalSteps - 4;
      }
      return this.totalSteps - 5;
    },
    navStepsCurrent() {
      switch (this.stepName) {
        case 'familyInfo':
          return 1;
        case 'selectSchedule':
          return 2;
        case 'questions':
          return 3;
        case 'subsidies':
          if (!this.hasQuestions) {
            return 3;
          }
          return 4;
        case 'paymentForm':
          if (this.questionsAndSubsidies) {
            return 5;
          }
          if (this.subsidiesNoQuestions || this.questionsNoSubsidies) {
            return 4;
          }
          return 3;
        default:
          return this.currentStep;
      }
    },
    hasQuestions() {
      return this.centerQuestions && this.centerQuestions.length > 0;
    },
    hasSubsidies() {
      return this.centerSubsidies
        && this.centerSubsidies.length > 0
        && this.legupStore.subsidyDiscount;
    },
    questionsAndSubsidies() {
      return this.hasQuestions && this.hasSubsidies;
    },
    questionsNoSubsidies() {
      return !this.hasSubsidies && this.hasQuestions;
    },
    subsidiesNoQuestions() {
      return !this.hasQuestions && this.hasSubsidies;
    },
    stepName() {
      return this.stepsData[this.currentStep].stepName;
    },
    legupStore() {
      return this.$store.state.legup;
    },
    hasCompletedTour() {
      return Object.keys(this.legupStore.pastTour).length > 0;
    },
    showHeader() {
      return !['confirmation', 'feeWarning', 'tourRequired'].includes(this.stepName);
    },
    nextStepBtnText() {
      return this.stepsData[this.currentStep].nextBtnText;
    },
    prevBtnText() {
      return this.stepsData[this.currentStep].prevBtnText;
    },
    stepTitle() {
      return this.stepsData[this.currentStep].title;
    },
    centerQuestions() {
      return this.legupStore.centerQuestions;
    },
    centerSubsidies() {
      return this.legupStore.centerInfo.subsidies ?? [];
    },
    requiredAnswers() {
      const { answers } = this.localWaitlistData;
      const requiredQuestionIds = this.centerQuestions?.filter((q) => q.required).map((q) => q.id);
      let matchingAnswers = [];
      if (answers.length) {
        matchingAnswers = answers.filter((a) => requiredQuestionIds.includes(a.questionId));
      }
      return matchingAnswers.length === requiredQuestionIds.length;
    },
    fullPaymentReceived() {
      const paymentMethods = this.currentPayment.paymentRequestMethods;
      return this.currentPayment.totalToBeCharged
        === paymentMethods.reduce((total, pm) => pm.totalAmount + total, 0);
    },
    disableButton() {
      const activeStep = this.stepName;
      return (activeStep === 'familyInfo' && (!regexPatterns.mobileNumber.regex.test(this.localWaitlistData.phoneNumber)
          || !this.localWaitlistData.phoneNumber || !this.localWaitlistData.dependentId))
        || (activeStep === 'selectSchedule' && this.ageGroupPrograms.length && !this.localWaitlistData.selectedSchedule.length)
        || (activeStep === 'questions' && this.centerQuestions.map((q) => (q.required ? this.localWaitlistData.answers[q.id] && !!this.localWaitlistData.answers[q.id].answer : true)).includes(false))
        || (activeStep === 'paymentForm' && !this.fullPaymentReceived) || this.loading;
    },
    olderChildren() {
      return this.dependentsOlderThanFacilityAgeRange(this.dependentValues);
    },
  },
  methods: {
    closeModalAndClear() {
      this.closeModal();
      this.clearData();
      this.clearPaymentMethods();
    },
    setLoading(value) {
      this.loading = value;
    },
    buttonStyleType() {
      if (this.currentStep < 2) {
        return 'secondary';
      }
      return 'clear';
    },
    lightFooter() {
      return this.currentStep > 1;
    },
    centerFooter() {
      return this.currentStep < 2;
    },
    confirmationStep() {
      return this.stepName === 'confirmation';
    },
    paymentStep() {
      return this.stepName === 'paymentForm';
    },
    onChange(data) {
      const newLocalWaitlistData = cloneDeep(this.localWaitlistData);
      Object.keys(data).forEach((key) => {
        newLocalWaitlistData[key] = data[key];
      });
      this.localWaitlistData = { ...newLocalWaitlistData };
    },
    onPrevStep() {
      if (this.currentStep <= 2) {
        this.closeModalAndClear();
        this.currentStep = 0;
      } else if (this.currentStep > 2) {
        this.currentStep -= 1;
      }
    },
    selectedDependent() {
      return this.$store.getters['dependents/dependentById'](this.localWaitlistData.dependentId);
    },
    hasPrograms() {
      return this.facility?.programs.filter((p) => p.legupAgeGroupId).length > 0;
    },
    async ageGroupProgramSchedules(dateOfBirth) {
      if (this.legupCenterId === undefined) {
        return;
      }

      await this.$store.dispatch('legup/dependentAgeGroup', {
        centerId: this.legupCenterId,
        childBirthDate: dateOfBirth,
      });

      const ageGroupId = this.legupStore.dependentAgeGroup;
      const programs = await this.$store.dispatch('legup/ageGroupProgramSchedules', { id: ageGroupId });
      if (programs.length) {
        this.ageGroupPrograms = programs;
      }
    },
    async clearPaymentMethods() {
      this.currentPayment.paymentRequestMethods.forEach((pm) => {
        this.$store.dispatch('payments/removeMethodFromCurrentPayment', pm.paymentMethodId);
      });
    },
    async checkForQuestionsDiscount() {
      const params = {
        answers: this.localWaitlistData.answers,
        provider_id: this.legupStore.centerInfo.provider_id,
        coupon_type: 'waitlist',
        waitlist_fee_total: this.legupStore.waitlistFees,
      };

      await this.$store.dispatch('legup/checkForProviderQuestionsDiscount', params);

      if (Object.prototype.hasOwnProperty.call(this.legupStore.couponInfo, 'discounted_total')) {
        await this.$store.dispatch('payments/setTotalAmount', this.legupStore.couponInfo.discounted_total);
        this.automaticDiscount = true;
      }
    },
    async checkForSubsidiesDiscount() {
      const params = {
        provider_id: this.legupStore.centerInfo.provider_id,
        coupon_type: 'waitlist',
        waitlist_fee_total: this.legupStore.waitlistFees,
      };

      await this.$store.dispatch('legup/checkForProviderSubsidiesDiscount', params);

      if (Object.prototype.hasOwnProperty.call(this.legupStore.couponInfo, 'discounted_total')) {
        await this.$store.dispatch('payments/setTotalAmount', this.legupStore.couponInfo.discounted_total);
        this.automaticDiscount = true;
      }
    },
    hasAnswers() {
      return this.localWaitlistData.answers.length;
    },
    async submitStep() {
      const activeStep = this.stepName;
      if (this.currentStep === this.totalSteps - 1) {
        const waitlistSpots = await this.$store.dispatch('getWaitlistSpotsWithCenters');
        // update the user's dependents in the store in case a new one was added
        this.user = this.$store.state.user;
        await this.$store.dispatch('dependents/fetchDependents', { parentId: this.user?.id });
        await this.$store.dispatch('user/fetchCurrent');
        this.closeModalAndClear();
        this.$emit('waitlist-joined', { waitlistSpots });
      } else if (activeStep === 'familyInfo' && this.hasPrograms()) {
        this.ageGroupPrograms = [];
        this.dependent = this.selectedDependent();
        this.setLoading(true);
        if (this.dependent) {
          await this.ageGroupProgramSchedules(this.dependent.dateOfBirth);
          this.currentStep += 1;
        } else {
          this.$store.dispatch('notifications/addToastError', { text: 'Could not load child information' });
        }
        await this.checkForSubsidiesDiscount();
        this.setLoading(false);
      } else if (activeStep === 'subsidies' && this.localWaitlistData.subsidies.length > 0) {
        this.setLoading(true);
        await this.checkForSubsidiesDiscount();
        this.setLoading(false);
        this.currentStep += 1;
      } else if (activeStep === 'questions' && this.hasAnswers()) {
        this.setLoading(true);
        await this.checkForQuestionsDiscount();
        this.setLoading(false);
        this.currentStep += 1;
      } else if (activeStep === 'tourRequired') {
        this.closeModal();
        if (!this.tourBooked) {
          this.openTourModal();
        }
      } else if (this.currentStep < (this.totalSteps - 1)) {
        this.currentStep += 1;
      }
    },
    clearData() {
      this.currentStep = 0;
      this.localWaitlistData.dependentId = undefined;
      this.localWaitlistData.startCare = '';
      this.localWaitlistData.phoneNumber = this.user.current.phoneNumber;
      this.localWaitlistData.parentSubsidiesEnabled = this.user.current.parentSubsidiesEnabled;
      this.localWaitlistData.parentComment = '';
      this.localWaitlistData.selectedSchedule = [];
      this.localWaitlistData.answers = [];
      this.localWaitlistData.subsidies = [];
      this.localWaitlistData.showSubsidies = undefined;
      this.legupStore.couponInfo = {};
    },
    onNextStep() {
      this.bus.$emit('on-next-step');
    },
    openTourModal() {
      this.$emit('open-tour-modal');
    },
    currentStepCalc() {
      if (!this.hasWaitlist && !this.hasCompletedTour) {
        this.currentStep = 0;
      } else if ((this.currentStep === 0 || !this.isOpen)
        && (this.hasWaitlist
        || (!this.hasWaitlist && this.hasCompletedTour))) {
        this.currentStep = 1;
      } else if (this.currentStep === 4 && (!this.hasQuestions && !this.hasSubsidies)) {
        this.currentStep = 6;
      } else if (this.currentStep === 4 && this.subsidiesNoQuestions) {
        this.currentStep = 5;
      } else if (this.currentStep === 5 && this.questionsNoSubsidies) {
        this.currentStep = 6;
      }
    },
  },
  mounted() {
    this.localWaitlistData.phoneNumber = this.user.current.phoneNumber;
    this.localWaitlistData.parentSubsidiesEnabled = this.user.current.parentSubsidiesEnabled;
  },
  watch: {
    legupCenterId: {
      immediate: true, // Run the handler immediately on component mount
      // eslint-disable-next-line no-unused-vars
      handler(newVal, oldVal) {
        if (newVal) {
          // If legupCenterId becomes available, dispatch the action
          this.$store.dispatch('legup/validateSubsidiesDiscount', newVal);
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.page__header {
  display: revert;
  font-family: var(--sans);
  font-size: var(--font-size-16);
  line-height: calc(var(--grid-unit) * 3);
  color: var(--viridian-5);
  text-align: center;
  text-transform: uppercase;
}

.step-title {
  font-family: var(--serif);
  font-size: calc(var(--grid-unit) * 2.5);
  font-weight: calc(var(--grid-unit) * 87.5);
  line-height: calc(var(--grid-unit) * 3.5);
}
</style>
