// If using this component, you must import the mixin profileComponentMixin as well.
// import profileComponentMixin from '@profile/mixins/profile-component';

import { toCamelCase } from '@utils';

export default {
  data() {
    return {
      isWaitlistModalOpen: false,
      isEditModalOpen: false,
    };
  },

  async mounted() {
    if (!this.facility?.id || this.facility.friendlyId !== this.friendlyId) {
      await this.loadFacility();
    }
    this.fetchLegupData();
    this.getWaitlistSpotCenters();
    this.checkForWaitlistSpots();
  },

  computed: {
    isLegupWaitlistJoined() {
      return this.waitlistSpotCenters
        && this.spot;
    },

    spot() {
      if (this.waitlistSpotCenters) {
        return this.waitlistSpotCenters.find((wl) => wl.center_id === this.legupCenterId);
      }

      return null;
    },
    dependentsWithSpots() {
      return this.$store.state.user.dependentsWithSpots;
    },
    waitlistSpotCenters() {
      return this.$store.state.waitlistSpotCenters;
    },
    legupCenterId() {
      return this.$store.state.legup.centerId;
    },
  },

  methods: {
    async getWaitlistSpotCenters() {
      if (!this.isAuthorized) {
        return;
      }

      this.$store.commit('setWaitlistSpotCenters', await this.$store.dispatch('getWaitlistSpotsWithCenters'));
    },

    async checkForWaitlistSpots() {
      if (this.isAuthorized) {
        await this.fetchLegupData();

        const waitlistSpots = await this.$store.dispatch('getWaitlistSpots');
        if (waitlistSpots && waitlistSpots.length) {
          const centerSpots = waitlistSpots.filter((ws) => (
            ws.center_id === this.legupCenterId
          ));
          if (centerSpots.length) {
            const spotChildIds = centerSpots.map((cs) => cs.child_id);
            const dependentIds = Object.values(this.$store.state.dependents.dependents)
              .map((dep) => (
                dep.legupChildId
              ));
            this.$store.commit('user/dependentsWithSpots', dependentIds.filter((id) => spotChildIds.includes(id)));
          }
        }
      }
    },

    openWaitlistModal() {
      this.isWaitlistModalOpen = true;
      this.trackLegupActionClick('join-waitlist');
    },

    closeWaitlistModal() {
      this.isWaitlistModalOpen = false;
    },

    openEditModal() {
      this.isEditModalOpen = true;
    },

    closeEditModal() {
      this.isEditModalOpen = false;
      this.active = false;
      this.checkForWaitlistSpots();
    },

    toCamelCase,
  },
};
