<template>
  <aside class="legup-actions">
    <box color="white" :big-radius="true">
      <div class="legup-actions__content">
        <template>
          <img
            class="legup-actions__center-logo"
            :src="providerLogo"
            alt="Provider logo"
            height="108"
          />
        </template>
        <template v-if="seatsAvailable">
          <p class="font-body-large open-spots">This provider has open spots!</p>
        </template>
        <template v-if="tourEnabled">
          <preview-mode-tooltip
            tooltipText="Kinside parents can click here to schedule a tour at your location."
          >
            <box v-if="isLegupTourBooked"
              class="mb-4 mark-done"
              :no-icon-bg="true"
              faIcon="check-circle"
            >
              Tour booked
            </box>
            <template v-else>
              <system-button
                v-on="!this.currentUserId() ? { click: () => openAuthModal('tour') } :
                !isPreviewMode ? { click: () => openTourModal() }  :  {}"
                fa-icon="calendar"
                target="_blank"
                class="mb-4 tour-button"
                full-width
              >
                <div class="legup-actions__button-content">
                  Book a tour
                </div>
              </system-button>
            </template>

            <legup-tour
              v-if="!loading && this.provider.currFacility"
              :facilityName="this.provider.currFacility.name"
              :facilityTimezone="this.provider.currFacility.timezone"
              :facilityPrograms="this.provider.currFacility.programs"
              :legupCenterId="this.provider.currFacility.legupCenterId"
              :isOpen="isTourModalOpen"
              :edit="editTour"
              :bookedTour="upcomingBookedTour"
              :closeModal="closeTourModal"
              @tour-booked="tourBooked"
              @tour-cancelled="onTourCancelled"
            />
          </preview-mode-tooltip>
        </template>
        <template v-if="waitlistEnabled">
          <p class="tip">
            Not ready to enroll yet?
          </p>
          <preview-mode-tooltip
            tooltipText="Kinside parents can click here to join your waitlist."
          >
            <system-button
              v-on="!this.currentUserId()
                ? { click: () => openAuthModal('waitlist') } : !isPreviewMode
                ? { click: () => openWaitlistModal() } : {}"
              style-type="secondary"
              target="_blank"
              class="mb-2 legup-actions__button"
              full-width
              :disabled="disableButton"
            >
              <div class="legup-actions__button-content">
                Join waitlist
              </div>
            </system-button>
          </preview-mode-tooltip>
        </template>
        <template v-if="isAuthorized && facility">
          <contact-button
            :provider-id="facility.providerId"
            :facility-id="facility.id"
            :email="facility.providerEmail"
            :phone-number="facility.phoneNumber"
            :facility-class="facility.facilityClass"
            :facility-name="facility.name"
            :full-width="true"
            :last-message-date="lastMessageDate"
          />
        </template>
        <template v-else-if="!isAuthorized">
          <contact-button
            :provider-id="0"
            :facility-id="0"
            :email="''"
            :phone-number="''"
            :facility-class="''"
            :facility-name="''"
            :full-width="true"
            :last-message-date="''"
          />
        </template>
      </div>
    </box>

    <profile-mobile-footer-nav
    :loading="loading"
    :facility="this.provider.currFacility"
    :lastMessageDate="lastMessageDate"
    :seatsAvailable="seatsAvailable"
    >
      <template v-if="!loading">
        <system-button
          v-if="tourEnabled && !isLegupTourBooked"
          v-on="!this.currentUserId() ? { click: () => openAuthModal('tour') } : !isPreviewMode
            ? { click: () => openTourModal() }  :  {}"
          fa-icon="calendar"
          target="_blank"
          class="tour-button"
          full-width
        >
          <div class="legup-actions__button-content">
            Book a tour
          </div>
        </system-button>

        <system-button
          v-else-if="(isLegupTourBooked || !tourEnabled) && !isLegupWaitlistJoined"
          v-on="!this.currentUserId()
            ? { click: () => openAuthModal('waitlist') } : !isPreviewMode
            ? { click: () => openWaitlistModal() } : {}"
          style-type="secondary"
          target="_blank"
          class="legup-actions__button"
          full-width
          :disabled="disableButton"
        >
          <div class="legup-actions__button-content">
            Join waitlist
          </div>
        </system-button>

        <box v-else-if="(isLegupTourBooked || !tourEnabled) && isLegupWaitlistJoined"
          class="mark-done"
          :no-icon-bg="true"
          faIcon="check-circle"
        >
          Tour booked
        </box>
      </template>
    </profile-mobile-footer-nav>

    <template v-if="loading">
      <loader :loading="loading" />
    </template>
    <template v-if="waitlistEnabled">
      <legup-waitlist
        :provider="provider"
        :isOpen="isWaitlistModalOpen"
        :closeModal="closeWaitlistModal"
        @waitlist-joined="waitlistJoined"
        :dependentsWithSpots="dependentsWithSpots"
        :hasWaitlist="hasWaitlist"
        :tourBooked="isLegupTourBooked"
        @open-tour-modal="openTourModal"
      />
    </template>
    <template v-if="enableSendPayment">
      <box color="white" :big-radius="true" class="mt-2 align-center">
        <span class="dollar-icon box--gold">
          <i class="fal fa-dollar-sign"></i>
        </span>
        <p class="pay-provider font-body-large
                  legup-actions__payment-box-header">
          Send a quick payment
        </p>
        <preview-mode-tooltip
            tooltipLabel="Send a payment"
            tooltipText="This is how a parent can send you a payment via Kinside
            for their tuition, deposit, and/or any other fees at your program."
        >
          <system-button
              :to="sendPaymentRoute()"
              full-width
              color="gold"
          >
            Pay provider
          </system-button>
        </preview-mode-tooltip>
      </box>
    </template>
  </aside>
</template>
<script>
import Box from '@components/box.vue';
import waitlistMixin from '@profile/mixins/waitlist';
import ProfileMobileFooterNav from '@profile/components/profile-mobile-footer-nav';
import Loader from '@components/loader/loader.vue';
import profileComponentMixin from '@profile/mixins/profile-component';
import SystemButton from '../buttons/system-button.vue';
import PreviewModeTooltip from './preview-mode-tooltip.vue';
import LegupTour from './legup-tour.vue';
import LegupWaitlist from './legup-waitlist.vue';
import ContactButton from './contact-button.vue';

export default {
  components: {
    SystemButton,
    Box,
    PreviewModeTooltip,
    LegupTour,
    LegupWaitlist,
    ContactButton,
    ProfileMobileFooterNav,
    Loader,
  },
  data() {
    return {
      editMode: false,
      loading: false,
      afterLoginDone: false,
    };
  },
  mixins: [
    waitlistMixin,
    profileComponentMixin,
  ],
  props: {
    trackClick: {
      type: Function,
    },
    upcomingBookedTour: {
      type: Object,
      required: false,
    },
    isTourModalOpen: {
      type: Boolean,
      required: true,
    },
    editTour: {
      type: Boolean,
      required: true,
    },
    logoUrl: {
      type: String,
      required: false,
    },
    tourEnabled: {
      type: Boolean,
      default: true,
    },
    seatsAvailable: {
      type: Boolean,
      default: false,
    },
    enableSendPayment: {
      type: Boolean,
      default: false,
    },
  },
  async mounted() {
    document.addEventListener('visibilitychange', this.handleVisibility, false);
  },
  async updated() {
    this.openModalAfterLogin();
  },
  methods: {
    async handleVisibility() {
      if (document.visibilityState !== 'hidden') {
        // user has returned to tab, fetch latest data from legup
        this.fetchLegupData();
      }
    },
    openAuthModal(action) {
      if (action === 'tour') {
        $('#registrationModal .title span').html('to book a tour');
      } else if (action === 'waitlist') {
        $('#registrationModal .title span').html('to join a wailist');
      }
      $('#registrationModal').modal('show');
      $('.login-signup-modal').attr('data-action-after-login', action);
    },
    openTourModal() {
      this.trackClick('tour');
      if (!this.isPreviewMode) {
        this.$emit('open-tour-modal');
      }
    },
    closeTourModal() {
      this.$emit('close-tour-modal');
    },
    tourBooked({ tour, ageGroupId }) {
      this.$emit('tour-booked', { tour, ageGroupId });
    },
    waitlistJoined({ waitlistSpots }) {
      this.$emit('waitlist-joined', { waitlistSpots });
    },
    onTourCancelled() {
      this.$emit('tour-cancelled');
    },
    currentUserId() {
      return !!this.$store.state.user.current.id;
    },
    openModalAfterLogin() {
      if (this.afterLoginDone || !this.currentUserId()
        || Object.keys(this.$store.state.legup.centerDirectory).length === 0) {
        return;
      }
      const actionModal = this.$route.query.action_after_login;
      if (actionModal === 'tour' && this.tourEnabled) {
        this.openTourModal();
      } else if ((actionModal === 'waitlist' && this.waitlistEnabled) || (this.openWaitlistForm && this.isAuthorized)) {
        this.openWaitlistModal();
      }
      this.afterLoginDone = true;
    },
    sendPaymentRoute() {
      return {
        name: 'send-payment',
        params: {
          state: this.$route.params.state,
          city: this.$route.params.city,
          facilityFriendlyId: this.$route.params.facilityFriendlyId,
        },
      };
    },
  },
  computed: {
    disableButton() {
      if (!this.isAuthorized) {
        return false;
      }

      return this.$store.state.legup.waitlistFees === null
        || !this.$store.state.user.current.parentPaymentsEnabled;
    },
    facility() {
      return this.provider.currFacility;
    },
    openWaitlistForm() {
      return this.$route.query.open_waitlist_form;
    },
    lastMessageDate() {
      return this.provider.lastMessageDate;
    },
    isLegupTourBooked() {
      return !!Object.keys(this.upcomingBookedTour).length;
    },
    providerLogo() {
      const defaultLogo = 'https://res.cloudinary.com/kinside/image/upload/v1627996200/app_assets/illustration-boygirl.png';

      return this.logoUrl || defaultLogo;
    },
    hasWaitlist() {
      return this.$store.getters['legup/centerHasWaitlistEnabled'];
    },
    waitlistEnabled() {
      if (this.openWaitlistForm && this.hasWaitlist) return true;
      if (!this.isAuthorized) return true;
      if (this.hasWaitlist) return true;
      if (!this.hasWaitlist
        && this.tourEnabled) {
        return true;
      }

      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.legup-actions {
  margin-bottom: calc(var(--grid-unit) * 4);

  @media screen and (min-width: 768px) {
    margin-bottom: calc(var(--grid-unit) * 6);
  }
}

.legup-actions__content {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.legup-actions__payment-box-header {
  color: var(--gray-80);
  text-align: center;
}

.legup-actions__center-logo {
  display: block;
  object-fit: contain;
  margin: 0 auto calc(var(--grid-unit) * 3);
  max-width: calc(var(--grid-unit) * 15);;
}

.legup-actions__no-center-logo-box {
  max-width: 185px;
  margin: 0 auto;
}

.legup-actions__powered-by-branding {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: calc(var(--grid-unit) * 2) 0;

  small {
    color: var(--gray-60);
  }

  img {
    display: block;
    margin-top: calc(var(--grid-unit) / 2);
    margin-left: calc(var(--grid-unit) / 2);
  }
}

.legup-actions__button {
  &-content {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .fal {
      transform: rotateZ(-45deg);
    }
  }

  &::v-deep .systemBtn__text {
    span:nth-child(2) {
      flex-basis: 100%;
    }
  }
}

.mark-done {
  justify-content: center;
  padding: calc(var(--grid-unit));
  border-radius: calc(var(--grid-unit) * 3);

  &::v-deep .fal {
    margin-right: var(--grid-unit);
  }
}

.open-spots {
  margin-bottom: calc(var(--grid-unit) * 2);
}

.tip {
  font-size: 16px;
  color: var(--gray-60);
  margin-bottom: var(--grid-unit)
}

.dollar-icon {
  display: inline-block;
  i {
    width: calc(var(--grid-unit) * 7) !important;
    height: calc(var(--grid-unit) * 7) !important;
  }
}
.pay-provider {
  padding-top: calc(var(--grid-unit) * 2);
  padding-bottom: calc(var(--grid-unit) * 2);
}
</style>
